import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useRef,
  useState,
} from 'react'
import { Mesh, Scene, Shape, Vector2, Vector3 } from 'three'
import { MatrixNode, getSplineFromShape } from './matrix-processor'
import { Knot, Spline } from 'lib/spline-classes'
import { constants, listOfPickerColors, modelTypes } from 'lib/constants'
import { IneShape } from 'lib/mesh-maker'
import { GeometryStateType } from 'lib/state'
import { Sphere } from 'lib/sphere'
import { Disk } from 'lib/disk'
import { CameraControls } from '@react-three/drei'

export function getXYPositions(cellSize: number, gridSize: number) {
  const [gridPositionsX, gridPositionsY] = (() => {
    const arrayLength = Math.floor(gridSize / (cellSize * 2))

    const positiveIndexes = [...Array(arrayLength).keys()]
    const negativeIndexes = positiveIndexes
      .filter((el) => el !== 0)
      .map((el) => -1 * el)
      .reverse()

    const xPositions = [...negativeIndexes, ...positiveIndexes].map(
      (el) => el * cellSize
    )
    const yPositions = [...xPositions].reverse()

    return [xPositions, yPositions]
  })()

  return [gridPositionsX, gridPositionsY]
}

export class WebsiteState {
  gridSize: number

  //number of squares per mm of grid size.
  gridSizeResolutionMultiplier: number = 1

  cellSize: number
  setCellSize: Dispatch<SetStateAction<number>>

  highResolutionCellMatrix: MatrixNode[][]
  setHighResolutionCellMatrix: Dispatch<SetStateAction<MatrixNode[][]>>

  cellMatrix: MatrixNode[][]
  setCellMatrix: Dispatch<SetStateAction<MatrixNode[][]>>

  mainIslandShape: Shape | undefined
  setMainIslandShape: Dispatch<SetStateAction<Shape | undefined>>

  allIslandShapes: Shape[] | undefined
  setAllIslandShapes: Dispatch<SetStateAction<Shape[] | undefined>>

  meshObject: Mesh
  setMeshObject: Dispatch<SetStateAction<Mesh>>

  threeDViewActive: boolean
  setThreeDViewActive: Dispatch<SetStateAction<boolean>>

  modelHeight: number
  setModelHeight: Dispatch<SetStateAction<number>>

  radiusRatio: number
  setRadiusRatio: Dispatch<SetStateAction<number>>

  brushType: boolean //true for adding and false for deleting
  setBrushType: Dispatch<SetStateAction<boolean>>

  cameraControlRef: MutableRefObject<CameraControls | null> = useRef(null)
  sceneRef: MutableRefObject<Scene | null> = useRef(null)

  private getInitialMatrix(gridSize: number, cellSize: number) {
    const numberOfColumns = Math.floor(gridSize / cellSize)

    const matrix = [...Array(numberOfColumns).keys()].map((_, xIndex) => {
      const yIndexes = [...Array(numberOfColumns).keys()]

      return yIndexes.map((_, yIndex) => {
        const xPosition = xIndex * cellSize + cellSize / 2 - this.gridSize / 2
        const yPosition =
          -1 * (yIndex * cellSize + cellSize / 2 - this.gridSize / 2)

        const newNode = new MatrixNode(
          { i: xIndex, j: yIndex },
          0,
          new Vector3(xPosition, yPosition, 0),
          cellSize
        )

        return newNode
      })
    })

    return matrix
  }

  getIndexFromPosition(position: { x: number; y: number }) {
    const xIndex = Math.floor(position.x / this.cellSize)
    const yIndex = Math.floor(position.y / this.cellSize)

    return { i: xIndex, j: yIndex }
  }

  constructor(gridSize: number = 2000) {
    this.gridSize = gridSize

    const [cellSize, setCellSize] = useState<number>(40)
    this.cellSize = cellSize
    this.setCellSize = setCellSize

    const initialCellMatrix = this.getInitialMatrix(gridSize, this.cellSize)
    const [cellMatrix, setCellMatrix] = useState(initialCellMatrix)
    this.cellMatrix = cellMatrix
    this.setCellMatrix = setCellMatrix

    const highResolutionInitialCellMatrix = this.getInitialMatrix(
      gridSize,
      gridSize * this.gridSizeResolutionMultiplier
    )
    const [highResolutionCellMatrix, setHighResolutionCellMatrix] = useState(
      highResolutionInitialCellMatrix
    )
    this.highResolutionCellMatrix = highResolutionCellMatrix
    this.setHighResolutionCellMatrix = setHighResolutionCellMatrix

    const [mainIslandShape, setMainIslandShape] = useState<Shape>()
    this.mainIslandShape = mainIslandShape
    this.setMainIslandShape = setMainIslandShape

    const [allIslandShapes, setAllIslandShapes] = useState<Shape[]>()
    this.allIslandShapes = allIslandShapes
    this.setAllIslandShapes = setAllIslandShapes

    const [meshObject, setMeshObject] = useState(new Mesh())
    this.meshObject = meshObject
    this.setMeshObject = setMeshObject

    const [threeDViewActive, setThreeDViewActive] = useState<boolean>(false)
    this.threeDViewActive = threeDViewActive
    this.setThreeDViewActive = setThreeDViewActive

    const [modelHeight, setModelHeight] = useState<number>(0.1)
    this.modelHeight = modelHeight
    this.setModelHeight = setModelHeight

    const [radiusRatio, setRadiusRatio] = useState<number>(1)
    this.radiusRatio = radiusRatio
    this.setRadiusRatio = setRadiusRatio

    const [brushType, setBrushType] = useState<boolean>(true)
    this.brushType = brushType
    this.setBrushType = setBrushType
  }

  getGeometryStateForMeshMaker(): GeometryStateType {
    const knots = [
      new Knot(new Vector2(0.01, 0), 1, false, false, true),
      new Knot(new Vector2(0.01, this.modelHeight), 1, false, false, true),
    ]

    const spline = new Spline(knots, modelTypes.waterDrop)
    const color = 'C0C0C0'
    const modelType = modelTypes.waterDrop
    const spheres = [] as Sphere[]
    const disks = [] as Disk[]
    const surfaceModifier = { name: constants.surfaceModifierNames.none }
    const additionalOptions = { topLipWidth: 0, bottomLipWidth: 0 }
    const splineForProfile = getSplineFromShape(
      this.mainIslandShape!,
      this.gridSize
    )
    const profiles = {
      startProfile: new IneShape(splineForProfile, 0, 0),
      endProfile: new IneShape(splineForProfile, 1, 0),
      intermediateShapes: [],
    }

    return {
      spline,
      color,
      modelType,
      spheres,
      disks,
      surfaceModifier,
      additionalOptions,
      profiles,
    }
  }
}
