import { CurvePath, Shape, Vector3 } from 'three'
import * as THREE from 'three'
import {
  GeneralCurvePath,
  GeneralSpline,
  IneSpline,
  Spline,
} from 'lib/spline-classes'
import { modelTypes } from 'lib/constants'
import { IneShape } from 'lib/mesh-maker'

const directions = {
  0: { previous: 0, next: 1 },
  1: { previous: 1, next: 2 },
  2: { previous: 2, next: 3 },
  3: { previous: 3, next: 0 },
}

function getDirection(previousVertex: NodeVertex, thisVertex: NodeVertex) {
  const foundObject = Object.entries(directions).find(
    (el) =>
      el[1].previous === previousVertex.vertexType &&
      el[1].next === thisVertex.vertexType
  )

  return Number(foundObject![0])
}

class NodeVertex {
  node: MatrixNode
  //0 for top left, 1 for top right, 2 for bottom right and 3 for bottom left
  vertexType: number

  constructor(node: MatrixNode, vertexType: number) {
    this.node = node
    this.vertexType = vertexType
  }

  isSameVertex(vertex: NodeVertex) {
    return (
      this.node.index.i === vertex.node.index.i &&
      this.node.index.j === vertex.node.index.j &&
      this.vertexType === vertex.vertexType
    )
  }

  getPosition() {
    switch (this.vertexType) {
      case 0:
        return new Vector3(
          this.node.position.x - this.node.cellSize / 2,
          this.node.position.y + this.node.cellSize / 2,
          0
        )
      case 1:
        return new Vector3(
          this.node.position.x + this.node.cellSize / 2,
          this.node.position.y + this.node.cellSize / 2,
          0
        )
      case 2:
        return new Vector3(
          this.node.position.x + this.node.cellSize / 2,
          this.node.position.y - this.node.cellSize / 2,
          0
        )
      case 3:
        return new Vector3(
          this.node.position.x - this.node.cellSize / 2,
          this.node.position.y - this.node.cellSize / 2,
          0
        )
      default:
        return new Vector3(0, 0, 0)
    }
  }
}

export class MatrixNode {
  index: { i: number; j: number }
  value: number
  island?: Island
  position: Vector3
  cellSize: number

  constructor(
    index: { i: number; j: number },
    value: number,
    position: Vector3,
    cellSize: number,
    island?: Island
  ) {
    this.index = index
    this.value = value
    this.position = position
    this.cellSize = cellSize
    this.island = island
  }

  isSameIndex(node: MatrixNode) {
    return this.index.i === node.index.i && this.index.j === node.index.j
  }

  getVertex(type: number) {
    return new NodeVertex(this, type)
  }
}

class Island {
  nodes: MatrixNode[]
  id: number

  constructor(nodes: MatrixNode[], id: number) {
    this.nodes = nodes
    this.nodes.forEach((el) => (el.island = this))
    this.id = id
  }

  addNode(node: MatrixNode) {
    node.island = this
    this.nodes.push(node)
  }

  addNodeToBeginning(node: MatrixNode) {
    node.island = this
    this.nodes.unshift(node)
  }

  mergeAndExhaustIsland(island: Island) {
    const nodesToAdd = island.nodes.splice(0)
    nodesToAdd.forEach((el) => this.addNode(el))
  }

  //orders two islands by the index of their top-left most node
  static orderIslands(island1: Island, island2: Island) {
    const indexTopLeft1 = island1.nodes[0].index
    const indexTopLeft2 = island2.nodes[0].index

    if (indexTopLeft1.j < indexTopLeft2.j) return [island1, island2]
    else if (
      indexTopLeft1.j === indexTopLeft2.j &&
      indexTopLeft1.i < indexTopLeft2.i
    )
      return [island1, island2]
    else return [island2, island1]
  }
}

export function findIslands(nodeMatrix: MatrixNode[][]) {
  const islands: Island[] = []

  const matrix = nodeMatrix.map((matrixRow) =>
    matrixRow.map((node) => node.value)
  )

  for (let jIndex = 0; jIndex < nodeMatrix[0].length; jIndex++) {
    for (let iIndex = 0; iIndex < nodeMatrix.length; iIndex++) {
      const curNode = nodeMatrix[iIndex][jIndex]
      curNode.island = undefined
      if (curNode.value !== 0) {
        const previousIIndex = curNode.index.i - 1
        const previousJIndex = curNode.index.j - 1
        const nextIIndex = curNode.index.i + 1
        const nextJIndex = curNode.index.j + 1

        if (
          previousIIndex >= 0 &&
          nodeMatrix[previousIIndex][curNode.index.j].value === 1
        ) {
          nodeMatrix[previousIIndex][curNode.index.j].island!.addNode(
            nodeMatrix[curNode.index.i][curNode.index.j]
          )
        } else if (
          previousJIndex >= 0 &&
          nodeMatrix[curNode.index.i][previousJIndex].value === 1
        ) {
          nodeMatrix[curNode.index.i][previousJIndex].island!.addNode(
            nodeMatrix[curNode.index.i][curNode.index.j]
          )
        } else if (
          previousIIndex >= 0 &&
          previousJIndex >= 0 &&
          nodeMatrix[previousIIndex][previousJIndex].value === 1
        ) {
          nodeMatrix[previousIIndex][previousJIndex].island!.addNode(
            nodeMatrix[curNode.index.i][curNode.index.j]
          )
        }

        if (previousJIndex >= 0 && nextIIndex < nodeMatrix[0].length) {
          if (nodeMatrix[nextIIndex][previousJIndex].value === 1) {
            if (curNode.island) {
              const orderedIslands = Island.orderIslands(
                curNode.island,
                nodeMatrix[nextIIndex][previousJIndex].island!
              )

              orderedIslands[0].mergeAndExhaustIsland(orderedIslands[1])
            } else {
              nodeMatrix[nextIIndex][previousJIndex].island!.addNode(curNode)
            }
          }
        }

        if (curNode.value === 1 && !curNode.island) {
          const newIsland = new Island([curNode], islands.length)
          islands.push(newIsland)
        }
      }
    }
  }
  return islands.filter((el) => el.nodes.length > 0)
}

function findVertexAndDirectionInDirection0(
  matrix: MatrixNode[][],
  currentVertex: NodeVertex
): [NodeVertex, number] {
  const upRightNode = matrix[currentVertex.node.index.i + 1]
    ? matrix[currentVertex.node.index.i + 1][currentVertex.node.index.j - 1]
    : undefined
  const rightNode = matrix[currentVertex.node.index.i + 1]
    ? matrix[currentVertex.node.index.i + 1][currentVertex.node.index.j]
    : undefined

  if (upRightNode?.value === 1) {
    const nextVertex = upRightNode.getVertex(0)
    const nextDirection = getDirection(upRightNode.getVertex(3), nextVertex)

    return [nextVertex, nextDirection]
  } else if (rightNode?.value === 1) {
    const nextVertex = rightNode.getVertex(1)
    const nextDirection = getDirection(rightNode.getVertex(0), nextVertex)

    return [nextVertex, nextDirection]
  } else {
    const nextVertex = currentVertex.node.getVertex(2)
    const nextDirection = getDirection(
      currentVertex.node.getVertex(1),
      nextVertex
    )

    return [nextVertex, nextDirection]
  }
}

function findVertexAndDirectionInDirection1(
  matrix: MatrixNode[][],
  currentVertex: NodeVertex
): [NodeVertex, number] {
  const downRightNode = matrix[currentVertex.node.index.i + 1]
    ? matrix[currentVertex.node.index.i + 1][currentVertex.node.index.j + 1]
    : undefined
  const downNode =
    matrix[currentVertex.node.index.i][currentVertex.node.index.j + 1]

  if (downRightNode?.value === 1) {
    const nextVertex = downRightNode.getVertex(1)
    const nextDirection = getDirection(downRightNode.getVertex(0), nextVertex)

    return [nextVertex, nextDirection]
  } else if (downNode?.value === 1) {
    const nextVertex = downNode.getVertex(2)
    const nextDirection = getDirection(downNode.getVertex(1), nextVertex)

    return [nextVertex, nextDirection]
  } else {
    const nextVertex = currentVertex.node.getVertex(3)
    const nextDirection = getDirection(
      currentVertex.node.getVertex(2),
      nextVertex
    )

    return [nextVertex, nextDirection]
  }
}

function findVertexAndDirectionInDirection2(
  matrix: MatrixNode[][],
  currentVertex: NodeVertex
): [NodeVertex, number] {
  const downLeftNode = matrix[currentVertex.node.index.i - 1]
    ? matrix[currentVertex.node.index.i - 1][currentVertex.node.index.j + 1]
    : undefined
  const leftNode = matrix[currentVertex.node.index.i - 1]
    ? matrix[currentVertex.node.index.i - 1][currentVertex.node.index.j]
    : undefined

  if (downLeftNode?.value === 1) {
    const nextVertex = downLeftNode.getVertex(2)
    const nextDirection = getDirection(downLeftNode.getVertex(1), nextVertex)

    return [nextVertex, nextDirection]
  } else if (leftNode?.value === 1) {
    const nextVertex = leftNode.getVertex(3)
    const nextDirection = getDirection(leftNode.getVertex(2), nextVertex)

    return [nextVertex, nextDirection]
  } else {
    const nextVertex = currentVertex.node.getVertex(0)
    const nextDirection = getDirection(
      currentVertex.node.getVertex(3),
      nextVertex
    )

    return [nextVertex, nextDirection]
  }
}

function findVertexAndDirectionInDirection3(
  matrix: MatrixNode[][],
  currentVertex: NodeVertex
): [NodeVertex, number] {
  const upLeftNode = matrix[currentVertex.node.index.i - 1]
    ? matrix[currentVertex.node.index.i - 1][currentVertex.node.index.j - 1]
    : undefined
  const upNode =
    matrix[currentVertex.node.index.i][currentVertex.node.index.j - 1]

  if (upLeftNode?.value === 1) {
    const nextVertex = upLeftNode.getVertex(3)
    const nextDirection = getDirection(upLeftNode.getVertex(2), nextVertex)

    return [nextVertex, nextDirection]
  } else if (upNode?.value === 1) {
    const nextVertex = upNode.getVertex(0)
    const nextDirection = getDirection(upNode.getVertex(3), nextVertex)

    return [nextVertex, nextDirection]
  } else {
    const nextVertex = currentVertex.node.getVertex(1)
    const nextDirection = getDirection(
      currentVertex.node.getVertex(0),
      nextVertex
    )

    return [nextVertex, nextDirection]
  }
}

function getNextVertexAndDirection(
  matrix: MatrixNode[][],
  currentVertex: NodeVertex,
  direction: number
): [NodeVertex, number] {
  switch (direction) {
    case 0:
      return findVertexAndDirectionInDirection0(matrix, currentVertex)
    case 1:
      return findVertexAndDirectionInDirection1(matrix, currentVertex)
    case 2:
      return findVertexAndDirectionInDirection2(matrix, currentVertex)
    case 3:
      return findVertexAndDirectionInDirection3(matrix, currentVertex)
    default:
      return findVertexAndDirectionInDirection0(matrix, currentVertex)
  }
}

export function getVerticesFromIsland(
  island: Island,
  matrix: MatrixNode[][]
): Vector3[] {
  const firstNode = island.nodes[0]
  const firstVertex = firstNode.getVertex(0)

  let currentVertex = firstNode.getVertex(1)
  let currentDirection = 0
  let nextVertex: NodeVertex
  let nextDirection: number

  const vertices: Vector3[] = []
  vertices.push(firstVertex.getPosition())

  while (!currentVertex.isSameVertex(firstVertex)) {
    ;[nextVertex, nextDirection] = getNextVertexAndDirection(
      matrix,
      currentVertex,
      currentDirection
    )

    if (nextDirection !== currentDirection) {
      vertices.push(currentVertex.getPosition())
      currentDirection = nextDirection
    }

    currentVertex = nextVertex
  }

  vertices.push(firstVertex.getPosition())

  return vertices
}

export function getShapeFromVertices(
  vertices: Vector3[],
  cellSize: number,
  radiusRatio: number
) {
  const shape = new Shape()

  const absoluteRadius = (radiusRatio * cellSize) / 2

  shape.moveTo(vertices[0].x + absoluteRadius, vertices[0].y)

  vertices.slice(1).forEach((el, index, arr) => {
    const previousIndex = index === 0 ? arr.length - 1 : index - 1
    const nextIndex = index === arr.length - 1 ? 0 : index + 1
    const isOnSameXAxis = Math.abs(arr[previousIndex].y - el.y) < 0.00001

    if (isOnSameXAxis) {
      const multiplierX = el.x - arr[previousIndex].x > 0 ? -1 : 1

      const currentPoint = new THREE.Vector2(
        el.x + multiplierX * absoluteRadius,
        el.y
      )
      shape.lineTo(currentPoint.x, currentPoint.y)

      const multiplierNextY = arr[nextIndex].y - el.y < 0 ? -1 : 1
      const nextPoint = new THREE.Vector2(
        el.x,
        el.y + multiplierNextY * absoluteRadius
      )

      const controlPoint1 = new THREE.Vector2(
        currentPoint.x + -1 * multiplierX * absoluteRadius * 0.552,
        currentPoint.y
      )
      const controlPoint2 = new THREE.Vector2(
        nextPoint.x,
        nextPoint.y + -1 * multiplierNextY * absoluteRadius * 0.552
      )

      shape.bezierCurveTo(
        controlPoint1.x,
        controlPoint1.y,
        controlPoint2.x,
        controlPoint2.y,
        nextPoint.x,
        nextPoint.y
      )
    } else {
      const multiplierY = el.y - arr[previousIndex].y > 0 ? -1 : 1

      const currentPoint = new THREE.Vector2(
        el.x,
        el.y + multiplierY * absoluteRadius
      )
      shape.lineTo(currentPoint.x, currentPoint.y)

      const multiplierNextX = arr[nextIndex].x - el.x < 0 ? -1 : 1
      const nextPoint = new THREE.Vector2(
        el.x + multiplierNextX * absoluteRadius,
        el.y
      )

      const controlPoint1 = new THREE.Vector2(
        currentPoint.x,
        currentPoint.y + -1 * multiplierY * absoluteRadius * 0.552
      )
      const controlPoint2 = new THREE.Vector2(
        nextPoint.x + -1 * multiplierNextX * absoluteRadius * 0.552,
        nextPoint.y
      )

      shape.bezierCurveTo(
        controlPoint1.x,
        controlPoint1.y,
        controlPoint2.x,
        controlPoint2.y,
        nextPoint.x,
        nextPoint.y
      )
    }
  })

  return shape
}

export function getSplineFromShape(shape: Shape, gridSize: number) {
  const curves = shape.curves.map((el) => {
    if (el instanceof THREE.LineCurve)
      return new THREE.LineCurve3(
        new Vector3(el.v1.x / gridSize, el.v1.y / gridSize, 0),
        new Vector3(el.v2.x / gridSize, el.v2.y / gridSize, 0)
      )
    else if (el instanceof THREE.CubicBezierCurve) {
      const v0 = new Vector3(el.v0.x / gridSize, el.v0.y / gridSize, 0)
      const v1 = new Vector3(el.v1.x / gridSize, el.v1.y / gridSize, 0)
      const v2 = new Vector3(el.v2.x / gridSize, el.v2.y / gridSize, 0)
      const v3 = new Vector3(el.v3.x / gridSize, el.v3.y / gridSize, 0)

      return new THREE.CubicBezierCurve3(v0, v1, v2, v3)
    } else throw 'All shapes curves must either be lines of cubic bezier curves'
  })

  const curvePath = new CurvePath<Vector3>()
  curvePath.curves = curves
  const generalCurvePath = new GeneralCurvePath(curvePath)
  const ineSpline = new IneSpline([], modelTypes.waterDrop, generalCurvePath)
  return ineSpline
}
