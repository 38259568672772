import { Flex } from '@chakra-ui/react'
import { BiCubeAlt } from 'react-icons/bi'
import { WebsiteState } from '../../lib/state'
import { event } from 'lib/gTag'

export default function ThreeDViewButton({
  websiteState,
}: {
  websiteState: WebsiteState
}) {
  const toggle3DView = () => {
    websiteState.setThreeDViewActive((v) => !v)
    event({
      action: ' 3D View Button Click',
      params: {
        search_term: '3D View',
      },
    })
  }

  return (
    <Flex zIndex={1} cursor={'pointer'} onClick={toggle3DView}>
      <BiCubeAlt fontSize={'3em'} />
    </Flex>
  )
}
