import { TbAugmentedReality } from 'react-icons/tb'
import { Flex } from '@chakra-ui/react'
import { BiCircle, BiSolidCircle, BiZoomIn, BiZoomOut } from 'react-icons/bi'
import { WebsiteState } from '../../lib/state'
import { USDZExporter } from 'three/examples/jsm/exporters/USDZExporter'
import { MeshMaker } from 'lib/mesh-maker'

export default function AugmentedRealityButton({
  websiteState,
}: {
  websiteState: WebsiteState
}) {
  const exporter = new USDZExporter()
  const exportUSDZ = async () => {
    const exportMesh = MeshMaker.getMeshARWaterDrop(
      websiteState.sceneRef.current!
    )

    const result = await exporter.parse(exportMesh)
    const link = document.createElement('a')
    link.rel = 'ar'
    document.body.appendChild(link)

    const image = document.createElement('img')
    image.src = 'static/background.png'
    link.appendChild(image)

    link.href = URL.createObjectURL(
      new Blob([result], { type: 'application/octet-stream' })
    )
    link.download = 'experience.usdz'

    link.click()

    document.body.removeChild(link)
  }

  return (
    <Flex marginRight={'1em'} cursor={'pointer'} onClick={exportUSDZ}>
      <TbAugmentedReality fontSize={'3em'} />
    </Flex>
  )
}
