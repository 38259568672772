import { Canvas, ThreeEvent, useLoader, useThree } from '@react-three/fiber'
import { useGesture } from '@use-gesture/react'
import {
  CameraControls,
  Circle,
  Html,
  OrbitControls,
  OrthographicCamera,
  Sphere,
  Text3D,
} from '@react-three/drei'
import CamControlsThree from 'camera-controls'
import {
  CircleGeometry,
  LineBasicMaterial,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Vector3,
} from 'three'
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import React from 'react'
import * as Three from 'three'
import { WebsiteState, getXYPositions } from '../lib/state'
import {
  MatrixNode,
  findIslands,
  getSplineFromShape,
  getShapeFromVertices,
  getVerticesFromIsland,
} from '../lib/matrix-processor'
import { MeshMaker } from 'lib/mesh-maker'
import { Flex } from '@chakra-ui/react'

function IslandShape({
  islandShape,
  color,
}: {
  islandShape: Three.Shape
  color?: Three.ColorRepresentation
}) {
  const shapeGeometry = new Three.ShapeGeometry(islandShape)

  const material = new MeshBasicMaterial({
    color: color ? color : 0x000000,
  })

  return (
    <mesh
      //@ts-ignore
      geometry={shapeGeometry}
      material={material}
      position={new Vector3(0, 0, 0.1)}
    />
  )
}

function CameraUpdater({
  cameraRef,
  cameraControlsRef,
  threeDViewActive,
}: {
  cameraRef: MutableRefObject<Three.OrthographicCamera | undefined>
  cameraControlsRef: MutableRefObject<CameraControls | null>
  threeDViewActive: boolean
}) {
  const t = useThree()

  useEffect(() => {
    if (!threeDViewActive) {
      cameraControlsRef.current!.reset(true)
    } else {
      cameraControlsRef.current!.saveState()
      cameraControlsRef.current!.moveTo(1100, 200, 1100, true)
      cameraControlsRef.current!.setTarget(0, 0, 0, true)
    }
  }, [threeDViewActive])
  return <></>
}

const mouseStateNoRotate = {
  left: CamControlsThree.ACTION.NONE,
  right: CamControlsThree.ACTION.TRUCK,
  middle: CamControlsThree.ACTION.ZOOM,
  wheel: CamControlsThree.ACTION.ZOOM,
}

const mouseStateRotate = {
  left: CamControlsThree.ACTION.ROTATE,
  right: CamControlsThree.ACTION.TRUCK,
  middle: CamControlsThree.ACTION.ZOOM,
  wheel: CamControlsThree.ACTION.ZOOM,
}

const touchStateNoRotate = {
  one: CamControlsThree.ACTION.NONE,
  two: CamControlsThree.ACTION.TOUCH_TRUCK,
  three: CamControlsThree.ACTION.TOUCH_DOLLY_OFFSET,
}

const touchStateRotate = {
  one: CamControlsThree.ACTION.TOUCH_ROTATE,
  two: CamControlsThree.ACTION.TOUCH_TRUCK,
  three: CamControlsThree.ACTION.TOUCH_DOLLY_OFFSET,
}

export default function ViewerEditor({
  websiteState,
}: {
  websiteState: WebsiteState
}) {
  const cameraControlRef: MutableRefObject<CameraControls | null> =
    websiteState.cameraControlRef
  const cameraRef: MutableRefObject<Three.OrthographicCamera | undefined> =
    useRef(undefined)

  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    if (hasMounted) {
      const islands = findIslands(websiteState.cellMatrix)
      const mainIsland = islands.sort(
        (a, b) => b.nodes.length - a.nodes.length
      )[0]

      if (mainIsland) {
        const mainIslandVertices = getVerticesFromIsland(
          mainIsland,
          websiteState.cellMatrix
        )
        const mainIslandShape = getShapeFromVertices(
          mainIslandVertices,
          websiteState.cellSize,
          websiteState.radiusRatio
        )

        websiteState.setMainIslandShape(mainIslandShape)
      } else {
        websiteState.setMainIslandShape(undefined)
        websiteState.setMeshObject(new Three.Mesh())
      }

      const remainingIslands = islands.slice(1)
      if (remainingIslands.length > 0) {
        const allIslandVertices = remainingIslands.map((island) =>
          getVerticesFromIsland(island, websiteState.cellMatrix)
        )
        const allIslandShapes = allIslandVertices.map((vertices) =>
          getShapeFromVertices(
            vertices,
            websiteState.cellSize,
            websiteState.radiusRatio
          )
        )

        websiteState.setAllIslandShapes(allIslandShapes)
      } else {
        websiteState.setAllIslandShapes(undefined)
      }
    }
  }, [websiteState.cellMatrix, hasMounted, websiteState.radiusRatio])

  useEffect(() => {
    if (websiteState.mainIslandShape && websiteState.threeDViewActive) {
      const geometryState = websiteState.getGeometryStateForMeshMaker()
      const meshMaker = new MeshMaker(geometryState, false, false)

      const meshObject = meshMaker.getMesh(true, false, 0.001, 0.5)

      meshObject[0].geometry.scale(10, 10, 10)
      websiteState.setMeshObject(meshObject[0])
    }
  }, [
    websiteState.mainIslandShape,
    websiteState.modelHeight,
    websiteState.threeDViewActive,
    websiteState.radiusRatio,
  ])

  const editorRef: MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement>(null)

  const [cameraControlsActive, setCameraControlsActive] = useState(false)

  const bind = useGesture(
    {
      onDrag: (state) => {
        const e = state.event

        if (state.touches > 1) setCameraControlsActive(true)

        if (state.last) setCameraControlsActive(false)

        const target = e.target as HTMLDivElement
        const bounds = target.getBoundingClientRect()

        const mousePosition = {
          x: ((state.xy[0] - bounds.left) * target.offsetWidth) / bounds.width,
          y: ((state.xy[1] - bounds.top) * target.offsetHeight) / bounds.height,
        }

        if (
          mousePosition.x > 0 &&
          mousePosition.y > 0 &&
          mousePosition.x < target.offsetWidth &&
          mousePosition.y < target.offsetHeight &&
          !websiteState.threeDViewActive &&
          state.touches == 1 &&
          !cameraControlsActive
        ) {
          const index = websiteState.getIndexFromPosition(mousePosition)

          const brushType = !state.altKey
            ? websiteState.brushType
            : !websiteState.brushType

          if (brushType)
            websiteState.setCellMatrix((matrix) => {
              matrix[index.i][index.j].value = 1

              return [...matrix]
            })
          else
            websiteState.setCellMatrix((matrix) => {
              matrix[index.i][index.j].value = 0

              return [...matrix]
            })
        }
      },
    },
    { drag: { pointer: { buttons: [1] }, delay: true } }
  )

  return (
    <Flex width={'100%'} height={'100%'} minWidth={0} zIndex={0}>
      <Canvas
        shadows
        orthographic
        onCreated={({ gl, camera, scene }) => {
          websiteState.sceneRef.current = scene

          const newCamera = camera as Three.OrthographicCamera
          newCamera.left = 200
          newCamera.right = -200
          newCamera.top = 200
          newCamera.bottom = -200
          newCamera.near = 1
          newCamera.far = 4000

          //this triggers a rerender that is needed
          setHasMounted(true)
        }}
      >
        <Html
          transform
          occlude={'blending'}
          prepend
          style={{
            width: websiteState.gridSize + 'px',
            height: websiteState.gridSize + 'px',
            outline: '2px dashed',
            outlineColor: 'rgba(255, 0, 0, 0.5)',
          }}
          distanceFactor={400}
          name='drawingBoard'
        >
          <Flex
            width={'100%'}
            height={'100%'}
            shrink={0}
            grow={0}
            {...bind()}
            zIndex={0}
            ref={editorRef}
          ></Flex>
        </Html>

        <ambientLight intensity={0.1} />

        <pointLight
          intensity={7}
          color={'white'}
          position={new Vector3(0, 0, 1100)}
        />

        <pointLight
          intensity={2}
          color={'white'}
          position={new Vector3(1100, 1100, 1100)}
        />

        <pointLight
          intensity={1}
          color={'white'}
          position={new Vector3(-1100, -1100, 1100)}
        />

        <pointLight
          intensity={2}
          color={'white'}
          position={new Vector3(-1100, 1100, 1100)}
        />

        <pointLight
          intensity={1}
          color={'white'}
          position={new Vector3(1100, -1100, 1100)}
        />

        {websiteState.mainIslandShape && (
          <IslandShape islandShape={websiteState.mainIslandShape} />
        )}

        {websiteState.allIslandShapes &&
          websiteState.allIslandShapes.map((islandShape) => (
            <IslandShape islandShape={islandShape} color={0x808080} />
          ))}

        <CameraControls
          ref={cameraControlRef}
          mouseButtons={
            websiteState.threeDViewActive
              ? mouseStateRotate
              : mouseStateNoRotate
          }
          touches={
            websiteState.threeDViewActive
              ? touchStateRotate
              : touchStateNoRotate
          }
          truckSpeed={30}
        />

        <CameraUpdater
          cameraRef={cameraRef}
          cameraControlsRef={cameraControlRef}
          threeDViewActive={websiteState.threeDViewActive}
        />

        {/* <CellGrid
        gridSize={websiteState.gridSize}
        cellSize={websiteState.cellSize}
        setCellMatrix={websiteState.setCellMatrix}
        threeDViewActive={websiteState.threeDViewActive}
      /> */}

        {/* <mesh position={[100, 0, 0]}>
        <sphereGeometry args={[50]} />
        <meshStandardMaterial color={'hotpink'} />
      </mesh> */}

        <mesh
          geometry={websiteState.meshObject.geometry}
          rotation={[(-1 * Math.PI) / 2, 0, 0]}
          castShadow
          receiveShadow
          name='objectOutside'
        >
          <meshStandardMaterial color={'gray'} side={Three.BackSide} />
        </mesh>

        <mesh
          geometry={websiteState.meshObject.geometry}
          rotation={[(-1 * Math.PI) / 2, 0, 0]}
          castShadow
          receiveShadow
          name='objectInside'
        >
          <meshStandardMaterial color={'red'} side={Three.FrontSide} />
        </mesh>
      </Canvas>
    </Flex>
  )
}
