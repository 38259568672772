import { Flex } from '@chakra-ui/react'
import { WebsiteState } from '../../lib/state'
import ThreeDViewButton from './3d-view-button'
import ResetButton from './reset-button'
import BrushButton from './brush-button'
import ZoomInButton from './zoom-in-button'
import { BiAlarm, BiZoomIn, BiZoomOut } from 'react-icons/bi'
import ZoomOutButton from './zoom-out-button'
import AugmentedRealityButton from './augmented-reality-button'

export default function Toolbar({
  websiteState,
}: {
  websiteState: WebsiteState
}) {
  return (
    <Flex
      position={'absolute'}
      zIndex={1}
      right={'1em'}
      //   fontSize={{ base: '0.5em', md: '1em' }}
    >
      {websiteState.threeDViewActive && (
        <AugmentedRealityButton websiteState={websiteState} />
      )}
      <BrushButton websiteState={websiteState} />
      <ResetButton websiteState={websiteState} />
      <ThreeDViewButton websiteState={websiteState} />
      <Flex direction={'column'} position={'absolute'} right={0} top={'4em'}>
        <ZoomInButton websiteState={websiteState} />
        <ZoomOutButton websiteState={websiteState} />
      </Flex>
    </Flex>
  )
}
