import { Flex } from '@chakra-ui/react'
import { BiCircle, BiSolidCircle } from 'react-icons/bi'
import { WebsiteState } from '../../lib/state'

export default function BrushButton({
  websiteState,
}: {
  websiteState: WebsiteState
}) {
  const changeBrushType = () => websiteState.setBrushType((v) => !v)

  return (
    <Flex marginRight={'1em'} cursor={'pointer'} onClick={changeBrushType}>
      {websiteState.brushType ? (
        <BiSolidCircle fontSize={'3em'} />
      ) : (
        <BiCircle fontSize={'3em'} />
      )}
    </Flex>
  )
}
