import { Flex } from '@chakra-ui/react'
import { BiCircle, BiSolidCircle, BiZoomIn } from 'react-icons/bi'
import { WebsiteState } from '../../lib/state'

export default function ZoomInButton({
  websiteState,
}: {
  websiteState: WebsiteState
}) {
  const zoomIn = () =>
    websiteState.cameraControlRef.current?.zoomTo(
      websiteState.cameraControlRef.current.camera.zoom * 1.5,
      true
    )

  return (
    <Flex marginTop={'1em'} cursor={'pointer'} onClick={zoomIn}>
      <BiZoomIn
        fontSize={'3em'}
        style={{ marginTop: '1em', marginBottom: '0.33em' }}
      />
    </Flex>
  )
}
