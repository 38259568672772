import { Flex } from '@chakra-ui/react'
import { BiReset } from 'react-icons/bi'
import { WebsiteState } from '../../lib/state'
import { event } from 'lib/gTag'

export default function ResetButton({
  websiteState,
}: {
  websiteState: WebsiteState
}) {
  const resetShapes = () => {
    websiteState.setCellMatrix((matrix) => {
      matrix.forEach((row) => row.forEach((el) => (el.value = 0)))

      return [...matrix]
    })
  }

  return (
    <Flex
      zIndex={1}
      marginRight={'1em'}
      cursor={'pointer'}
      onClick={resetShapes}
    >
      <BiReset fontSize={'3em'} />
    </Flex>
  )
}
