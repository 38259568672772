import { Flex } from '@chakra-ui/react'
import { BiCircle, BiSolidCircle, BiZoomIn, BiZoomOut } from 'react-icons/bi'
import { WebsiteState } from '../../lib/state'

export default function ZoomOutButton({
  websiteState,
}: {
  websiteState: WebsiteState
}) {
  const zoomOut = () => {
    websiteState.cameraControlRef.current?.zoomTo(
      websiteState.cameraControlRef.current.camera.zoom * 0.75,
      true
    )
  }

  return (
    <Flex marginTop={'1em'} cursor={'pointer'} onClick={zoomOut}>
      <BiZoomOut fontSize={'3em'} />
    </Flex>
  )
}
