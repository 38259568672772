import {
  Button,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react'

import ViewerEditor from '../components/viewer-editor'
import { WebsiteState } from '../lib/state'
import Toolbar from '../components/tools-menu/toolbar'
import HeightSlider from '../components/height-slider'
import RadiusSlider from '../components/radius-slider'
import { GCODEOptionsHidden } from 'gcode/components/gcode-generation'
import Head from 'next/head'
import { useState, useEffect } from 'react'
import { merge, assocPath, get as get_in } from 'lodash/fp'
import dynamic from 'next/dynamic'
import { Suspense } from 'react'
import {
  Login,
  initial_state as login_state,
  LOGGED_IN,
  AUTH_STATE,
  USERNAME,
  check_session,
  logout,
  CHECKING_SESSION,
} from 'components/login'

function initial_state() {
  return login_state()
}

function LoggedIn(props) {
  let state = props.state
  let set_state = props.set_state
  const DummySlicer = dynamic(() => import('./slicer.js'), {
    suspense: true,
  })

  return (
    <Flex direction={'column'} height={'100vh'} width={'100vw'}>
      <Flex>
        logged in as {state[USERNAME]}
        <Button onClick={logout(state, set_state, initial_state())}>
          Logout
        </Button>
      </Flex>
      <Flex height={'100%'}>
        <Suspense fallback={<div>Loading...</div>}>
          <DummySlicer websiteState={props.websiteState} />
        </Suspense>
      </Flex>
    </Flex>
  )
}

export function GCodeTab(props) {
  const [state, set_state] = useState(initial_state())
  useEffect(() => {
    check_session(state, set_state)
  }, [])
  if (state[AUTH_STATE] === CHECKING_SESSION) {
    return <div>Loading ...</div>
  } else if (state[AUTH_STATE] === LOGGED_IN) {
    return (
      <LoggedIn
        state={state}
        set_state={set_state}
        websiteState={props.websiteState}
      />
    )
  } else {
    return (
      <div>
        <Flex
          p={'15px'}
          fontSize={{ base: '0.5em', md: '1em' }}
          direction={{ base: 'column', md: 'row' }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Head>
            <title>Lyla Designer</title>
            <meta
              name='description'
              content='Make your own 3D printable designs'
            />
            <link rel='icon' href='/favicon.ico' />
          </Head>
          <Login state={state} set_state={set_state} />
        </Flex>
      </div>
    )
  }
}

function DrawingTab(props) {
  return (
    <Flex
      backgroundColor={'white'}
      padding={'1em'}
      direction={'column'}
      height={'100%'}
      width={'100%'}
    >
      <Toolbar websiteState={props.websiteState} />
      <ViewerEditor websiteState={props.websiteState} />
      {props.websiteState.threeDViewActive && (
        <HeightSlider websiteState={props.websiteState} />
      )}
      {!props.websiteState.threeDViewActive && (
        <RadiusSlider websiteState={props.websiteState} />
      )}
    </Flex>
  )
}

function MainTabs(props) {
  return (
    <Tabs height={'100%'} width={'100%'}>
      <TabList>
        <Tab>Sketch</Tab>
        <Tab>GCode</Tab>
      </TabList>
      <TabPanels padding={0} height={'calc(100% - 3em)'}>
        <TabPanel padding={0} height={'100%'} width={'100%'}>
          <DrawingTab websiteState={props.websiteState} />
        </TabPanel>
        <TabPanel padding={0} height={'100%'} width={'100%'}>
          <GCodeTab websiteState={props.websiteState} />
        </TabPanel>
      </TabPanels>
      <TabPanels></TabPanels>
    </Tabs>
  )
}

export default function Home() {
  const websiteState = new WebsiteState(1000)

  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => setHasMounted(true), [])

  return (
    <Flex
      backgroundColor={'white'}
      padding={'1em'}
      height={hasMounted ? window.innerHeight : '100vh'}
      width={'100%'}
      direction={'column'}
    >
      <MainTabs websiteState={websiteState} />
    </Flex>
  )
}
