import {
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react'
import { WebsiteState } from '../lib/state'
import { useEffect } from 'react'

export default function HeighSlider({
  websiteState,
}: {
  websiteState: WebsiteState
}) {
  return (
    <Flex justifyContent={'center'}>
      <Slider
        aria-label='slider-ex-1'
        min={0}
        max={1}
        step={0.01}
        defaultValue={websiteState.modelHeight}
        onChange={websiteState.setModelHeight}
        width={'80%'}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </Flex>
  )
}
